import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { FaqHeroSection, FaqContentSection } from '../components/sections';

const FaqPage: React.FC = () => (
  <Layout>
    <SEO title="FAQ" />
    <FaqHeroSection />
    <FaqContentSection />
  </Layout>
);

export default FaqPage;
